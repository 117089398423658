var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "top-nav-wrap" }, [
    _c("div", { staticClass: "left-log" }, [
      _c("img", {
        attrs: {
          src: _vm.getFileUrl(_vm.componyMeta.loginLogo),
          alt: "",
          height: "80%",
        },
      }),
      _c("span", { staticClass: "logoname" }, [_vm._v("法律知识库")]),
    ]),
    _c(
      "div",
      { staticClass: "right-operate" },
      [
        _c("en-icon", {
          attrs: {
            name: "sousuolansousuo",
            size: "22",
            color: "#ffffff",
            title: "搜索",
          },
          nativeOn: {
            click: function ($event) {
              return _vm.handleScreen.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }